






























import Vue from 'vue';
import LoginForm from '../../components/auth/LoginForm.vue';
import TheAppInfo from '../../components/app/TheAppInfo.vue';

export default Vue.extend({
  name: 'LoginPage',

  components: {
    LoginForm,
    TheAppInfo,
  },
});
