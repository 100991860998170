








































































































































































import data from '../../data.json';
import Vue from 'vue';

enum Tab {
  News,
  Contact,
  Demo,
  Documents,
}

export default Vue.extend({
  name: 'TheAppInfo',

  data() {
    return {
      news: data.news,
      Tab,
      activeTab: Tab.News,
      documentsHeaders: [
        {
          text: this.$t('component.theAppInfo.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('component.theAppInfo.type'),
          sortable: false,
          value: 'type',
        },
        {
          text: this.$t('component.theAppInfo.date'),
          sortable: false,
          value: 'date',
        },
      ],
      documents: [
        {
          name: 'Building Product Declaration',
          href:
            'https://www.swegon.com/globalassets/_product-documents/system-products/_sv/tbsc-bd.pdf',
          type: 'Declarations',
          date: '2017-04-12',
        },
        {
          name: 'Installation Instructions',
          href:
            'https://www.swegon.com/globalassets/_product-documents/system-products/_en/tbsc_router_installation.pdf',
          type: 'Instructions/Manuals',
          date: '2017-07-03',
        },
        {
          name: 'Overview',
          href:
            'https://www.swegon.com/globalassets/_product-documents/system-products/_sv/swegonconnect_mbrochure.pdf',
          type: 'Brochures',
          date: '2017-10-11',
        },
        {
          name: 'Product Brochure',
          href:
            'https://www.swegon.com/globalassets/_product-documents/system-products/_sv/swegonconnect_brochure.pdf',
          type: 'Catalogue/Product Sheets',
          date: '2017-07-03',
        },
        {
          name: 'Swegon Connect Security',
          href:
            'https://www.swegon.com/globalassets/_product-documents/system-products/_sv/tbsc-bd.pdf',
          type: 'Brochures',
          date: '2017-09-18',
        },
      ],
    };
  },
});
