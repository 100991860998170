



















































import Vue from 'vue';
import { VForm } from '../../plugins/vuetifyTypes';
import { AuthService } from '../../services/auth/AuthService';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../services/snackbar/SnackbarService';
import { formValidator } from '../../utils/FormValidator';

export default Vue.extend({
  name: 'LoginForm',

  data() {
    return {
      formValidator,
      isLoading: false,
      username: '',
      password: '',
    };
  },

  async mounted(): Promise<void> {
    await this.$nextTick();

    // ? Force input event with current value to ensure labels are animated on autocomplete/autofill.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const username = this.$refs.username as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const password = this.$refs.username as any;
    username.$emit('input', username.value);
    password.$emit('input', password.value);
  },

  methods: {
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        if (validation) {
          await AuthService.login(this.username, this.password);
          this.$router.go(0);
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        SnackbarService.open(
          this.$t('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
        this.isLoading = false;
      }
    },
  },
});
